import React from "react";
import Home from "../components/home";
import NavButton from "../components/navButton";

function HomePage() {
  return (
    <>
      <Home />
      <NavButton />
    </>
  );
}

export default HomePage;
