import React from "react";
import Signup from "../components/signup";

function SignupPage() {
  return (
    <>
      <Signup />
    </>
  );
}

export default SignupPage;
