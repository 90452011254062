import React from "react";
import Landing from "../components/landing";

function LandingPage() {
  return (
    <>
      <Landing />
    </>
  );
}

export default LandingPage;
